/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import {MnD3Test} from "./MnD3Test";


@NgModule({
    imports: [
    ],
    declarations: [
        MnD3Test
    ],
    entryComponents: [
        MnD3Test
    ]
})
export class MnD3Module {
    static forRoot(): MnD3Module {
        return {
            ngModule: MnD3Module
            //providers: []
        };
    }
}

export class mn_template {
    static configure():any {
        return [
            /*MnRouteModule.forRoot(),
            UIRouterModule.forRoot({
                states: [],
                useHash: true
            })*/
        ];
    }
}